import React from 'react';
import Sidebar from '../../components/Sidebar';
import TopBar from '../../components/TopBar';
function Dashboard() {
  return (
    <div className='kidsdetails_Container'>
    <TopBar />
    <div className='rowView'>
        <div className='left'>
            <Sidebar />
        </div>
        <div className='right'>
            <h1>Dashboard</h1>
        </div>
    </div>
</div>
  )
}

export default Dashboard