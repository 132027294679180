import React from 'react';
import "./PaymentStatus.scss";
import Sidebar from '../../components/Sidebar';
import TopBar from '../../components/TopBar';
function PaymentStatus() {
  return (
    <div className='payment_Container'>
      <TopBar />
      <div className='rowView'>
        <div className='left'>
          <Sidebar />
        </div>
        <div className='right'>
          <h1>Payment Status</h1>
        </div>
      </div>
    </div>
  )
}

export default PaymentStatus;