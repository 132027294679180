import React from 'react';
import "./ForgotPassword.scss";
import Logo from "../../assets/images/forgot_password.png";
import Logo_Banner from "../../assets/images/logo_banner.png";
import { useNavigate } from "react-router-dom";
function ForgotPassword() {
    let navigate = useNavigate();
    return (
        <div>
            <div className="grid">
                <div className="order__left centered">
                    <div className="form">
                        <img className="astro_forgor_password centered" src={Logo} alt="astrocure_logo" />
                        <p className='login-header-text'>Forgot Password</p>
                        <p className="forgot-description-text">Enter the email address associated with your account, then check your email for a verification code. If you forgot your email, please contact us.</p>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Enter your email address"
                            className='login-input-view'
                            autocomplete="off" />                    
                        <button type="submit" class="login__button login__text" onClick={()=>navigate("/verify_code")}>Send Verification Code</button>
                        <button type="submit" class="forgot__button forgot__text" onClick={()=>navigate("/")}>Back to Login</button>
                        <div class="signup">
                            <label className="remember_me">
                                Copyright © 2024</label><a href="#" class="forgot__password"><strong>Astrovidhi Private Limited</strong> </a> <label className="remember_me"> All rights reserved</label>
                        </div>
                    </div>
                </div>
                <div class="order__right centered no__overflow">
                    <img class="img" src={Logo_Banner} alt="picture" />
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword;