import React from 'react';
import "./Sidebar.scss";
import Dashboard_Icon from "../assets/images/dashboard.png"
import Kids_Details_Icon from "../assets/images/kids_details.png";
import Payment_Status_Icon from "../assets/images/payment_status.png";
import Logout_Icon from "../assets/images/logout.png";
function Sidebar() {
    return (
        <div>
            <div className="container">                
                <div className="sidebar-out">
                    <div className="sidebar">
                        <div className="nav">
                            <div className="menu">
                                <ul>
                                    <li><a href="/dashboard"><img className='dasboard_icon_style' src={Dashboard_Icon} alt="dashboard_icon"/><span className="text">Dashboard</span></a></li>
                                    <li><a href="/kids_details"><img className='dasboard_icon_style' src={Kids_Details_Icon} alt="dashboard_icon"/><span className="text">Kid’s Details</span></a></li>
                                    <li><a href="/payment_status"><img className='dasboard_icon_style' src={Payment_Status_Icon} alt="dashboard_icon"/><span className="text">Payment Status </span></a></li>
                                    <li><a href="/"><img className='dasboard_icon_style' src={Logout_Icon} alt="dashboard_icon"/><span className="text">Log Out</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;