import React from 'react';
import "./ForgotPassword.scss";
import Logo from "../../assets/images/email.png";
import Logo_Banner from "../../assets/images/logo_banner.png";
import { useNavigate } from "react-router-dom";
function VerifyCode() {
    let navigate = useNavigate();
    return (
        <div>
            <div className="grid">
                <div className="order__left centered">
                    <div className="form">
                        <img className="astro_forgor_password centered" src={Logo} alt="astrocure_logo" />
                        <p className='login-header-text'>Verify Code</p>
                        <p className="forgot-description-text">A verification code has been sent to your email address from demo.gmail.com. Didn’t receive the email? Check spam folder.</p>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Enter verification code "
                            className='login-input-view'
                            autocomplete="off" />                    
                        <button type="submit" class="login__button login__text" onClick={()=>navigate("/create_new_password")}>Verify Code</button>
                        <button type="submit" class="forgot__button forgot__text">Resend Code</button>
                        <div class="signup">
                            <label className="remember_me">
                                Copyright © 2024</label><a href="#" class="forgot__password"><strong>Astrovidhi Private Limited</strong> </a> <label className="remember_me"> All rights reserved</label>
                        </div>
                    </div>
                </div>
                <div class="order__right centered no__overflow">
                    <img class="img" src={Logo_Banner} alt="picture" />
                </div>
            </div>
        </div >
    )
}

export default VerifyCode;