import React from 'react';
import "./KidsDetails.scss";
import Sidebar from '../../components/Sidebar';
import TopBar from '../../components/TopBar';
function KidsDetails() {
    return (
        <div>
            <TopBar />
            <div class="content CF">
            <Sidebar />
                <article class="information">
                 
                </article>
                <nav class="menu">
                    Menu
                </nav>
            </div>
        </div>

    )
}

export default KidsDetails;