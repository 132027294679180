import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import VerifyCode from "./pages/forgotPassword/VerifyCode";
import CreateNewPassword from "./pages/forgotPassword/CreateNewPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import KidsDetails from "./pages/kidsDetails/KidsDetails";
import PaymentStatus from "./pages/paymentStatus/PaymentStatus";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/verify_code" element={<VerifyCode />} />
          <Route path="/create_new_password" element={<CreateNewPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/kids_details" element={<KidsDetails />} />
          <Route path="/payment_status" element={<PaymentStatus />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;