import React from 'react';
import "./Login.scss";
import Logo from "../../assets/images/logo.png";
import Logo_Banner from "../../assets/images/logo_banner.png";
import { useNavigate } from "react-router-dom";
function Login() {
    let navigate = useNavigate();
    return (
        <div>
            <div className="grid">
                <div className="order__left centered">
                    <div className="form">
                        <img class="astro_logo centered" src={Logo} alt="astrocure_logo" />
                        <p className='login-header-text'>Welcome To AstroCure! </p>
                        <p className='login-label-text'>Login</p>
                        <p className='login-description-text'>Login to access your  account</p>
                        <p className="login-empty-text">---------------------------------------------------------------------------------------------</p>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Email Id"
                            className='login-input-view'
                            autocomplete="off" />
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            autocomplete="off" />
                        <div class="justify__space_between">
                            <span>
                                <input type="checkbox" id="remember_me" name="remember_me" value="remember_me" />
                                <label for="remember_me" class="remember_me">Remember Me</label><br />
                            </span>
                            <a href="/forgot_password" class="forgot__password">Forgot Password?</a>
                        </div>
                        <button type="submit" class="login__button login__text" onClick={()=>navigate("/dashboard")}>Login</button>
                        <div class="signup">
                            <label className="remember_me">
                                Copyright © 2024</label><a href="/forgot_password" class="forgot__password"><strong>Astrovidhi Private Limited</strong> </a> <label className="remember_me"> All rights reserved</label>
                        </div>
                    </div>
                </div>
                <div class="order__right centered no__overflow">
                    <img class="img" src={Logo_Banner} alt="picture" />
                </div>
            </div>
        </div >
    )
}

export default Login;