import React, { useEffect, useState } from 'react';
import "./Topbar.scss";
import Logo from "../assets/images/logo.png";
import DateIcon from "../assets/images/date_icon.png";
import ProfileIcon from "../assets/images/profile_icon.png";
import moment from "moment";
function TopBar() {
    const now = new Date();
    let currentDateTime = now.toLocaleString();
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(now.toLocaleString());
        }, 1000);
        return () => clearInterval(interval);
    }, [count]);
    return (
        <div className='topbar'>
            <img className='topbar_logo' src={Logo} alt="logo" resize="contain" />
            <div className='rightside_view'>
                <img className='topbar-date-icon' src={DateIcon} alt="dateicon" />
                <p className='topbar-date-text'>{moment(currentDateTime).format("MM/DD/YYYY")}</p>
                <p className='topbar-date-text'>{moment(currentDateTime).format("h:mm:ss A")}</p>
                <p className='topbar-date-text'>Nandhakumar</p>
                <img className='topbar-profile-icon' src={ProfileIcon} alt="dateicon" />
            </div>
        </div>
    )
}

export default TopBar